<template>
  <div class="user-info padding-20">
    <div class="user-info-avatar">
      <img :src="marketingUser.headImgUrl" alt="" />
    </div>
    <div class="user-info-detail">
      <div class="detail-label mr-24">
        <div>归属人</div>
        <div>手机</div>
        <div>公司</div>
      </div>
      <div class="detail-data">
        <div class="overflow" title="">
          {{ marketingUser.promoterUserName || '-' }}
        </div>
        <div class="overflow">{{ marketingUser.mobileNum || '-' }}</div>
        <div class="overflow">
          {{ marketingUser.promoterUserCompany || '-' }}
        </div>
      </div>
    </div>
    <div class="user-info-detail">
      <div class="detail-label">
        <div>微信</div>
        <div>邮箱</div>
        <div></div>
      </div>
      <div class="detail-data">
        <div class="overflow">{{ marketingUser.wechatAccount || '-' }}</div>
        <div class="overflow">{{ marketingUser.email || '-' }}</div>
        <div class="overflow">{{ marketingUser.city || ' ' }}</div>
      </div>
    </div>
  </div>
  <div class="user-opportunity padding-20">
    <div>
      <p>推广情况</p>
      <div class="opportunity-detail pl-20 pr-20 pt-16 pb-16">
        <div>
          <div>传播人数</div>
          <div>{{ marketingUser.communicateCount }}</div>
        </div>
        <div>
          <div>访问人数</div>
          <div>{{ marketingUser.visitorNumber }}</div>
        </div>
        <div>
          <div>访问次数</div>
          <div>{{ marketingUser.visitCount }}</div>
        </div>
        <div>
          <div>转化人数</div>
          <div>{{ marketingUser.conversionNumber }}</div>
        </div>
      </div>
    </div>
    <div class="mt-16">
      <div class="flex pt-16 pb-16">
        <div>
          <span
            v-if="tabOne"
            :class="{ 'iss-tab': true, 'active-tab': active }"
            @click="handleClickTabOne"
            >传播者数据</span
          >
          <span
            v-if="tabTwo"
            :class="{ 'iss-tab': true, 'active-tab': !active }"
            @click="handleClickTabTwo"
            >留资数据</span
          >
        </div>
        <!--        <span class="fw-4 export-btn" @click="handleClickExport">-->
        <!--          <ExportOutlined class="mr-4 export-icon" />-->
        <!--          导出列表-->
        <!--        </span>-->
      </div>
      <grid
        v-if="active && tabOne"
        ref="grid1Ref"
        :scroll="{ x: 1000, y: 380 }"
        :url="tab1url"
        :columns="columns1"
        :pagination="{ showTotal: null }"
        :url-params="{ promoteId: configData.id, rootUserId: getRootUserId }"
      >
        <template #userName="{ text, record }">
          <div class="iss-name-wrap">
            <div :title="text">{{ text }}</div>
            <div>
              <PartitionOutlined
                class="icon-color cursor-p"
                @click="handleClickLink(record)"
              />
            </div>
          </div>
        </template>
      </grid>
      <grid
        ref="grid2Ref"
        v-if="!active && tabTwo"
        :scroll="{ x: 1000, y: 380 }"
        :url="tab2url"
        :columns="columns2"
        :pagination="{ showTotal: null }"
      >
      </grid>
      <a-modal
        title="传播链路"
        v-model:visible="modalVisible"
        width="736px"
        :centered="true"
        @ok="modalVisible = false"
      >
        <modal-link :list="modalList"></modal-link>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue';
import grid from '@/components/grid';
import { local } from '@/utils/storage';
import { Modal } from 'ant-design-vue';
import ModalLink from './modalLink';
import { /*ExportOutlined*/ PartitionOutlined } from '@ant-design/icons-vue';
import iRadarApi from '@/api/iRadar';
export default {
  name: 'detailDrawer',
  components: {
    grid,
    // ExportOutlined,
    AModal: Modal,
    ModalLink,
    PartitionOutlined,
  },
  props: {
    visible: Boolean,
    marketingUser: Object,
    promoteUserId: [Number, String],
  },
  setup(props) {
    const grid1Ref = ref();
    const grid2Ref = ref();
    const configData = local.get('configData');
    let tab1url = iRadarApi.pageUrl;
    // eslint-disable-next-line vue/no-setup-props-destructure
    const getRootUserId = props.promoteUserId;

    let tab2url = ref(
      `/marketing/marketingUserRetention/submitForm/data/${configData.id}/${configData.promoteType}/${props.promoteUserId}`
    );
    const tabOne = configData.promoteType === 5 || configData.isFission;
    const tabTwo =
      [1].includes(configData.promoteType) ||
      (configData.promoteType === 5 && configData.promoteUrlType === '1');
    const state = reactive({
      active: tabOne ? true : false,
      modalVisible: false,
      modalList: [],
    });
    return {
      grid1Ref,
      grid2Ref,
      tabOne,
      tabTwo,
      configData,
      getRootUserId,
      ...toRefs(state),
      tab1url,
      tab2url,
      handleClickTabOne() {
        state.active = true;
      },
      handleClickTabTwo() {
        state.active = false;
      },
      columns1: [
        {
          dataIndex: 'userName',
          title: '姓名',
          width: 120,
          slots: { customRender: 'userName' },
        },
        {
          dataIndex: 'mobileNum',
          title: '手机',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'companyName',
          title: '公司',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'parentUserName',
          title: '上级',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'promoteUserName',
          title: '推广者',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'createTime',
          title: '传播时间',
          width: 160,
          ellipsis: true,
        },
        {
          dataIndex: 'uv',
          title: '访问人数',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'pv',
          title: '访问次数',
          ellipsis: true,
        },
      ],
      columns2: [
        {
          dataIndex: 'userName',
          title: '姓名',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'mobileNum',
          title: '手机',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'companyName',
          title: '公司',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'communicateUserName',
          title: '传播者',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'promoteUserName',
          title: '推广者',
          ellipsis: true,
        },
      ],
      handleClickLink(record) {
        iRadarApi
          .getMarketingCommunicateChain('', {
            communicateUserId: record.id,
            promoteId: local.get('configData').id,
            rootUserId: record.rootUserId,
          })
          .then(res => {
            state.modalList = res;
            state.modalVisible = true;
          });
      },
    };
  },
};
</script>

<style lang="less" scoped>
.user-info {
  background: #f7f8fa;
  height: 120px;
  display: flex;
  .user-info-avatar {
    width: 100px;
    height: 80px;
    padding-right: 20px;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .user-info-detail {
    width: 330px;
    height: 80px;
    display: flex;
    margin-right: 14px;
    .detail-label,
    .detail-data {
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      line-height: 1.9;
      font-family: PingFangSC-Regular, PingFang SC;
      & > div {
      }
    }
    .detail-label {
      color: #86909c;
      width: 66px;
    }
    .detail-data {
      color: #1d2129;
      div {
        width: 264px;
      }
    }
  }
}
.user-opportunity {
  font-family: PingFangSC-Regular, PingFang SC;
  p {
    font-weight: 600;
    font-family: PingFangSC-Semibold, PingFang SC;
  }
  .opportunity-detail {
    display: flex;
    border-radius: 4px;
    border: 1px solid #e5e6eb;
    & > div {
      width: 25%;
      height: 44px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & > div:nth-of-type(1) {
        line-height: 14px;
      }
      & > div:nth-of-type(2) {
        font-size: 16px;
        line-height: 16px;
        color: @primary-color;
        font-weight: 600;
      }
    }
  }
}
.export-icon {
  color: @primary-color;
}
.export-btn {
  cursor: pointer;
}
.iss-tab {
  padding: 8px 0;
  font-weight: bold;
  margin-right: 32px;
  cursor: pointer;
  color: #86909c;
  transition: color 0.3s;
  &.active-tab {
    color: @primary-color;
    border-bottom: 2px solid @primary-color;
  }
}
.iss-name-wrap {
  display: flex;
  div:nth-of-type(1) {
    width: 66px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 8px;
  }
}
.icon-color {
  color: #5979f8;
}
</style>
