<template>
  <div class="link-wrap">
    <template v-for="(item, index) in arr" :key="index + 1">
      <div
        :class="{ 'iss-row-link': index + 1 !== arr.length, 'iss-row': true }"
        :style="{ justifyContent: list.length < 6 ? 'center' : '' }"
      >
        <template v-for="(data, i) in item" :key="i + 1">
          <div
            :class="{
              'iss-arrow': (i + 1) % 6 !== 0 && i + 1 !== item.length,
              'iss-link': (i + 1) % 6 === 0,
              'iss-tag': true,
              'last-tag': i + 1 === item.length && index + 1 === arr.length,
            }"
            :style="{
              background:
                data.userType === 1 && data.enabled ? '#d9f7be' : '#ffe7ba',
            }"
            :title="data.userName"
          >
            {{ data.userName }}
          </div>
          <div class="link-1" v-if="(i + 1) % 6 !== 0 && i + 1 !== item.length">
            <img src="../../../assets/images/link-1.png" alt="" />
          </div>
        </template>
      </div>
      <div class="link-2" v-if="index + 1 !== arr.length">
        <img src="../../../assets/images/link-2.png" alt="" />
      </div>
    </template>
    <div class="link-tip">
      <a-space :size="90">
        <div class="flex">
          <a-space>
            <div class="link-1-color"></div>
            <span>传播者</span>
          </a-space>
        </div>
        <div class="flex">
          <a-space>
            <div class="link-2-color"></div>
            <span>推广者</span>
          </a-space>
        </div>
      </a-space>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { Space } from 'ant-design-vue';
export default {
  name: 'modalLink',
  components: {
    ASpace: Space,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const length = computed(() => props.list.length);
    const arr = computed(() => {
      let arr = [];
      let times = Math.ceil(props.list.length / 6);
      for (let i = 0; i < times; i++) {
        arr.push(props.list.slice().splice(6 * i, 6));
      }
      return arr;
    });
    return {
      length,
      arr,
    };
  },
};
</script>

<style scoped lang="less">
.link-wrap {
  width: 680px;
  .link-tip {
    width: 100%;
    margin-top: 24px;
    text-align: center;
  }
}
.iss-row {
  display: flex;
  position: relative;
  //&.iss-row-link::before {
  //  content: '';
  //  width: 40px;
  //  height: 32px;
  //  border-left: 1px @primary-color solid;
  //  position: absolute;
  //  //transform: translate(-50%, -50%);
  //  top: 38px;
  //  left: 38px;
  //}
}
.link-1-color {
  border-radius: 2px;
  width: 12px;
  height: 12px;
  background-color: #ffe7ba;
}
.link-2-color {
  border-radius: 2px;
  width: 12px;
  height: 12px;
  background-color: #d9f7be;
}
.iss-tag {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
  padding: 2px 8px;
  //background-color: #ffe7ba;
  text-align: center;
  //margin-right: 40px;
  //margin-bottom: 32px;
  border-radius: 2px;
  position: relative;
  &.last-tag {
    //background-color: #d9f7be;
  }
  //&.iss-arrow::after {
  //  content: '';
  //  width: 0px;
  //  height: 0px;
  //  border: 6px solid;
  //  border-top-color: transparent;
  //  border-bottom-color: transparent;
  //  border-left-color: @primary-color;
  //  border-right-color: transparent;
  //  position: absolute;
  //  transform: translate(-50%, -50%);
  //  top: 50%;
  //  left: 120px;
  //}
  //&.iss-arrow::before {
  //  content: '';
  //  width: 40px;
  //  height: 6px;
  //  border-top: 1px @primary-color solid;
  //  position: absolute;
  //  //transform: translate(-50%, -50%);
  //  top: 11px;
  //  right: -40px;
  //}
  //&.iss-link::after {
  //  content: '';
  //  width: 0px;
  //  height: 0px;
  //  border: 6px solid;
  //  border-top-color: @primary-color;
  //  border-bottom-color: transparent;
  //  border-left-color: transparent;
  //  border-right-color: transparent;
  //  position: absolute;
  //  //transform: translate(-50%, -50%);
  //  top: 48px;
  //  left: -567px;
  //}
  //&.iss-link::before {
  //  content: '';
  //  width: 602px;
  //  height: 16px;
  //  border-right: 1px @primary-color solid;
  //  border-bottom: 1px @primary-color solid;
  //  position: absolute;
  //  //transform: translate(-50%, -50%);
  //  top: 22px;
  //  right: 40px;
  //}
}
.link-1 {
  width: 40px;
  img {
    width: 100%;
    height: 100%;
    height: 8px;
  }
}
.link-2 {
  img {
    height: 32px;
    width: 600px;
    transform: translateX(35px);
  }
}
.iss-br {
  //&::before {
  //  content: '';
  //  width: 40px;
  //  height: 6px;
  //  border-top: 1px @primary-color solid;
  //  position: absolute;
  //  //transform: translate(-50%, -50%);
  //  top: 11px;
  //  right: 80px;
  //}
}
</style>
