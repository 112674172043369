'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/marketing/marketingCommunicateChain/page',
  getQrCode(code) {
    return http.get('/marketing/iradar/manager/wxappcode', { code });
  },
  getMarketingCommunicateChain(
    code,
    { communicateUserId, promoteId, rootUserId }
  ) {
    return http.get(
      `/marketing/marketingCommunicateChain/listUp/${communicateUserId}/${promoteId}/${rootUserId}`,
      { code }
    );
  },
};
